.button-pair-data-operator {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.hollow-button {
  width: 150px;
  margin-right: 20px;
}

.primary-button {
  width: 150px;
}

.avatar-container {
  display: flex;
}

.avatar {
  width: 80%;
  height: 80%;
}

.heading-text {
  color: #222222;
  font-size: 20px !important;
}