     #harmacy-ims{
 .pharmacy .dashboard-table-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    // margin-left: 0;
    outline: none;
    border: none;
    box-shadow: 0px 3px 6px #00000029;

    // margin-right: 5%;
  }
   .pharmacy .dashboard-table {
    width: 100%;
  }
   .pharmacy .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .pharmacy thead {
    height: 60px;
    // color: black;
    font-size: 16px;
    font-weight: 500;
    background: #EFEEFE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;

     th {
      text-align: center;
      font-weight: bold;
      // color: white;
    }
}
 .pharmacy tbody {
    tr {
      box-shadow: none !important;

      border: none !important;
      height: 60px;
    }

    td {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
    }
  }
  .box{
flex-basis: 0;
flex-grow: 1;
  }
  .desc-box{
    height:80%;
  }

     }
///////////////////////////////////////////////////////
     #pharmacy-view{
         .pharmacy .dashboard-table-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    // margin-left: 0;
    outline: none;
    border: none;
    // margin-right: 5%;
  }
   .pharmacy .dashboard-table {
    width: 100%;
    border-collapse: collapse;
  }
   .pharmacy .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .pharmacy thead {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: #A8A8F9 0% 0% no-repeat padding-box;
    border: 2px solid #A8A8F9;
     th {
      text-align: center;
      color: white;
    }
}
 .pharmacy tbody {
    tr {
      box-shadow: none !important;

      border: none !important;
      height: 60px;
    }

    td {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
      border:2px solid #00000029;
    }
  }
  .box{
flex-basis: 0;
flex-grow: 1;
  }
  .desc-box{
    height:80%;
  }
  .header-container{
    background-color: #A8A8F9;
    border-radius: 5px;
    color: white;
    margin-bottom: 30px;
    padding: 15px 30px;
  }
     }
     
    