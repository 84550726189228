.hospital-form-heading {
  font-size: 20px;
  color: #222222;
  margin-bottom: 30px;
  font-weight: bold;
}
.reset-btn-mch {
  padding: 5px 40px !important;
  border:1px solid #7997F7 !important;
  border-radius: 12px !important; 
  color:#7997F7 !important;
  margin-right: 10px;



}