.dashboard .dashboard-table-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 5px;

}

.dashboard .dashboard-table {
  margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
  // border: 0.5px solid #707070;
  width: 95%;
  border-collapse: separate;
}

.dashboard .graph-border {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

  margin-top: 12px;
  border-radius: 5px;
}

.dashboard-table-style {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.dashboard .dashboard-table thead {
  height: 60px;
  color: #16161c !important;
  font-size: 16px;
  font-weight: 500;
  // text-align: center;
}


@media only screen and (max-width: 992px) {
  .dashboard .dashboard-table-container {

    width: fit-content !important;
  }

  .dashboard .mobile-class {
    width: unset !important;
  }

  .dashboard-table tbody tr td {
    padding: 20px;
  }

  .dashboard .table-style {
    overflow: scroll !important;
  }

  .dashboard .healthCamp_container {
    overflow-x: hidden;
  }

  .dashboard .responsive-search-add {
    display: flex !important;
    flex-direction: column !important;
  }

}


// .dashbord-table-header{
//   color: red;
//   background-color: red !important;
// }