#hello {
    // border: 0.1px solid #707070;
        //  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;

table {
border-collapse: separate;
border-spacing: 0 10px;
}
thead{
height: 0px;
color:#5355D8
}
thead tr {
text-align: center;
height: 20px;

// background-color: aqua;
}
tbody tr {
box-shadow: 2px 2px 2px 3px #00000029;
    border: 0.5px solid #707070;
    border-radius: 10px;
// background-color: aqua;
}

td {
text-align: center;
    border: 0.5px solid #707070;
// padding: 10px;
}

td:first-child {
// border-top: 0.5px solid #707070;
// border-left: 0.5px solid #707070;
// border-bottom: 0.5px solid #707070;
border-top-left-radius: 10px;
border-bottom-left-radius: 10px;
text-align: center;
}

td:last-child {
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}

tr td:first-child,
tr td:last-child {
border: 1px solid #707070;
}

tr td:not(:first-child):not(:last-child) {
border-right: none;
border-left: none;
border: none;
}

tr td:first-child {
border-right: none;
border: none;
}

tr td:last-child {
border-left: none;
border: none;
}

//   .patientdo{
//     border: 1px solid black;
//     pa
//   }
}
