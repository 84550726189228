#generalInfo{
 border:0.5px solid #70707080 !important;
    padding: 15px;
.grid-container{
   display: grid;
  grid-template-columns: 1fr 2fr;
//   grid-template-rows: auto;
  grid-gap: 10px;
  align-items: start; 
  text-align: left;
}
.right-border{
    border-right: 3px solid #EFEEFD;
    
}

.custom-opacity-label {
  opacity: 0.5;
}


.font-style{
    font-weight: bold !important;
}



}

#nurseHealthInfo{
    //  border:0.5px solid #70707080 !important;
     box-shadow: 0px 3px 6px #00000029;

    padding: 15px;
.reset-btn-nhealth {
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px !important;
border-radius: 10px;
width: 200px !important;
color:#1E64D5 !important;

background-color: transparent !important;
border:2px solid   #1E64D5 !important;
}
.reset-btn-nhealth-save{
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px !important;
border-radius: 10px;
width: 200px !important;
color:white !important;

background-color:  #1E64D5 !important;
border:2px solid   #1E64D5 !important;
}




}

#tabelInfo{
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th{
    border: 1px solid black;
    text-align: left;
    padding: 8px;
  }
}

.grid-container-view{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:10px;
    align-items: start;
    text-align: left;
    // padding-left: 20%;
    // padding-right: 20%;
}
.grid-container-view-vitals{
        display: grid;
 grid-gap:10px;
        grid-template-columns:1fr 2fr;
           align-items: start;
    text-align: left;
    border-left: 1px solid #00000029;
    padding-left: 70px;

}
.grid-container-view-vitals:first-child {
  border-left: none; /* remove the left border from the first grid container */
}
.btn-container{
      flex: 1;
  display: flex;
  justify-content: center;
}

.text-color{
  color: #191BC3;
}

// .grid-container-view-vitals:








