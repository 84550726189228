.common-popup {
    width: 90vw;
    max-width: 90vw;

    p{
        margin: 0;
        padding: 0;
    }

    input[type=checkbox] {
        position: relative;
        cursor: pointer;
    }

    input[type=checkbox]:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0;
        left: 0;
        border: 2px solid #7997F7;
        border-radius: 3px;
        background-color: white;
    }

    input[type=checkbox]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: solid #7997F7;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 3px;
        left: 6.5px;
    }

    .assign-modal-header {
        padding: 0 !important;
        justify-content: flex-end;
        border-bottom: none;
    }

    .select-input {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #70707080;
        border-radius: 5px;
        width: 90%;
        padding: 1% 2%;
        min-height: 44px;
    }

    .label-input {
        position: relative;
        width: 100%;
    }

    .search-btn {
        background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 5px 8px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 10%;
        width: 6rem;
    }

    .search-input {
        border-radius: 5px;
        padding-left: 22px;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        min-height: 44px;
    }

    .search-btn-close {
        position: absolute;
        border: none;
        width: 25px;
        height: 25px;
        line-height: 16px;
        font-size: 12px;
        background: white;
        top: 0;
        bottom: 0px;
        right: 100px;
        margin: auto;
        padding: 0;
        outline: none;
        cursor: pointer;
        transition: 0.1s;
        z-index: 10;
        display: block;
    }

    .apply-btn {
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: none;
        padding: 0.5rem 1rem;
    }

    .table-container {
        margin-top: 2rem;

        th {
            min-width: 3rem;
        }

        thead {
            background: #5355D8 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            height: 44px;
            font-size: 15px;
            font-weight: 500;
            color: #ffff;
        }

        tbody {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;

            td {
                padding: 0.5rem;
            }

            td:first-of-type {
                text-align: center;
            }
        }
    }
}