#healthCamp_container {
    margin-left: 20%;
    font-family: 'Roboto';
    height: 87%;
    overflow: auto;

    p {
        margin: 0;
        padding: 0;
    }

    .label-input {
        position: relative;
        width: 100%;
    }

    .search-btn {
        background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 26px;
        padding: 5px 8px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 4%;
    }

    .search-input {
        border-radius: 26px;
        padding-left: 22px;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .search-btn-close {
        position: absolute;
        border: none;
        width: 25px;
        height: 25px;
        line-height: 16px;
        font-size: 12px;
        background: white;
        top: 0;
        bottom: 0px;
        right: 89px;
        margin: auto;
        padding: 0;
        outline: none;
        cursor: pointer;
        transition: 0.1s;
        z-index: 10;
        display: block;
    }

    .filter-btn {
        background: #5355D8 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
        min-width: 5rem;
    }

    .create-health-camp-btn {
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
    }

    .healthcamp-table {
        width: 102%;
        border-collapse: separate;
        margin-left: -7px;
    }

    .healthCamp-table-container {

        margin: 14px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;


        padding: 28px;

    }
}

thead {
    height: 60px;
    color: #191BC3;
    font-size: 16px;
    font-weight: 500;

    th:first-child,
    th:last-child {
        text-align: center;
    }
}

tbody {
    tr {
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid #707070;
        height: 60px;
    }

    td:first-child,
    td:last-child {
        // border-top: 0.5px solid #707070;
        // border-left: 0.5px solid #707070;
        // border-bottom: 0.5px solid #707070;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        text-align: center;
    }

    // td{
    //     border-top: 0.5px solid #707070;
    //     border-bottom: 0.5px solid #707070;
    // }
}

.warning-text-card {
    min-height: 8rem;
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
}