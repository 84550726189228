#Prescription{

   width: 100%;
   .dashboard-table-container{
         box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 5px;
  
  }

.dashboard-table{
      margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
      // border: 0.5px solid #707070;
      width: 95%;
      border-collapse: separate;
  }
  
  .graph-border{
    box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
   
      margin-top: 12px;
      border-radius: 5px;
   
      
      
  }

  .back-button-click{
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

   .dashboard-table thead {
      height: 60px;
      color: #16161c !important;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
  }

  .grid-container{
   display: grid;
  grid-template-columns: 1fr 2fr;
//   grid-template-rows: auto;
  grid-gap: 10px;
  align-items: start; 
  text-align: left;

}
.grid-container p{
  font-size: 16px;
}
.doctor-info p:first-child{
font-size: 16px;
font-weight: bold;
}
.doctor-info p:nth-child(2){
font-size: 12px;
// font-weight: bold;
}
.medical-prescription{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,

}
.medical-prescription > .text-center > p{
  font-size: 30px;
  font-weight: bold;
}
.medical-prescription > .text-center > p:nth-child(2){
 font-size: 20px;
 font-weight: normal;
}

.doctor-info-container{

  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
}

  

  
  @media only screen and (max-width: 992px) {
 .dashboard-table-container{

  width:fit-content !important;
}
  .mobile-class{
  width: unset !important;
}
 .dashboard-table tbody tr td{
  padding: 20px;
}
.table-style{
  overflow: scroll !important;
}
 .healthCamp_container{
  overflow-x: hidden;
}
  .responsive-search-add{
  display: flex !important;
  flex-direction: column !important;
}

    } 
}