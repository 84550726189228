.newPasswordContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.forgotText {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #4A7BF1;
}

.passwordText {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #000352;
}

.formLabel {
    font: normal normal medium 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.newPassword-formControl {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 42px;
}

.passwordIntoText {
    font: normal normal 300 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    max-width: 383px;
    text-align: center;
    line-height: 21px;
}

.newPasswordForm {
    max-width: 383px;
    width: 100%;
}

.newPassword-removeRedEyeIcon {
    right: 25px;
    position: absolute;
    top: 40px;
}

.newPassword-ChangePassword {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0.32px;
    color: #FFFFFF;
    opacity: 1;


    background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: none;
    border-radius: 5px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    text-align: center;
}