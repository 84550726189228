#uploadReportDetails {
  .uploadReport {
    // display: flex;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #fff;
    outline: none;
    // height: 10rem;
    // width: 100%;
  }

  .report-class {
    padding: 3%;
  }

  .upload-report-button {
    white-space: no-wrap;
  }
}