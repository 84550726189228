#doctorIdd {
  box-shadow: none !important;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  thead {
    height: 0px;
    color: #5355D8
  }

  thead tr {
    text-align: center;
    height: 20px;

    // background-color: aqua;
  }

  tbody tr {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    // background-color: aqua;
  }

  td {
    text-align: center;
    border: 0.5px solid #707070;
    // padding: 10px;
  }

  td:first-child {
    // border-top: 0.5px solid #707070;
    // border-left: 0.5px solid #707070;
    // border-bottom: 0.5px solid #707070;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
  }

  td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  tr td:first-child,
  tr td:last-child {
    border: 1px solid #707070;
  }

  tr td:not(:first-child):not(:last-child) {
    border-right: none;
    border-left: none;
  }

  tr td:first-child {
    border-right: none;
  }

  tr td:last-child {
    border-left: none;
  }
}

.reset-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 5px;
  border-radius: 10px;
  width: 130px;
  padding: 20px 40px !important;
}

.form-style {
  // border: 1px solid black !important;
  border-radius: 20px;
  margin-top: 20px;
  margin: 20px 20px;
  //  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;

}

.file-upload-bus {
  margin: 10px 0px;
  border-radius: 10px;
  width: 100%;
}

.file-upload-inner-bus {
  width: 100%;
  padding: 10px 0px;
  // height: 230px;
  // background-color: #efeefe;
  cursor: pointer;
  border-radius: 10px;
  // border: 2px solid black;
}

.MuiTablePagination-selectLabel {
  margin: auto !important;
}

.box-border {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
}