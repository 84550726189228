.button-pair {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
}

.hollow-button {
  width: 150px;
  margin-right: 20px;
}

.primary-button {
  width: 150px;
}

.avatar-container {
  display: flex;
}

.avatar {
  width: 80%;
  height: 80%;
}