#parmacist-med {
    // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;

    box-shadow: 0px 1px 3px #00000033;
    padding: 1%;
    outline: none;
    border: none;

    h5 {
        margin-left: 5%;
        margin-right: 5%;
        color: #191BC3;
        font-weight: bold;
    }

    .header-parm {
        margin-left: 5%;
        margin-right: 5%;
        gap: 10px;
        padding: 2%;
        border-radius: 5px;
        background-color: #EFEEFE;
        border: none;
        outline: none;
        margin-bottom: 1%;
    }

    // #pharmacy-tab {
    .table-border tbody tr td {
        border: 0.5px solid #707070 !important;
    }

    .top-spacing {
        margin-top: 20px;
    }

    .topbar-main-container {
        background-color: #191BC3;
        color: #fff;
        padding: 12px 30px;
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        cursor: pointer;
    }

    .tabs-main-container {
        margin-bottom: 20px;
    }

    .pharmacy-table-overwrite {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .pdf-div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px
    }

    .add-button-pharmacy {
        color: #191BC3;
        font-weight: 500;
    }

    .horizontal-gap {
        margin: 0px 5%;
    }

    .refer-to-mch-label {
        display: flex;
        justify-content: flex-start;
        font-weight: 500;
    }

    .new-medicaldiagnosis-title-container {
        padding: 10px 1.7rem !important;
        background-color: #EFEEFE;
        font-weight: 600;
    }

    table {
        border-collapse: separate !important;
        border-spacing: 0 10px;
    }

    .border-collapse {
        border-collapse: collapse !important
    }

    table {
        border-collapse: separate;
        border-spacing: 0 10px;
    }

    thead {
        height: 0px;
        color: #5355D8
    }

    thead tr {
        text-align: center;
        height: 60px;
        background-color: #8484F6;

        // background-color: aqua;
    }

    thead tr th {
        color: #fff;
    }

    tbody tr {
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid #707070;
        // background-color: aqua;
    }

    td {
        text-align: center;
        border: 0.5px solid #707070 !important;
        // padding: 10px;
    }

    td:first-child {
        // border-top: 0.5px solid #707070;
        // border-left: 0.5px solid #707070;
        // border-bottom: 0.5px solid #707070;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
    }

    td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    tr td:first-child,
    tr td:last-child {
        border: 1px solid #707070;
    }

    tr td:not(:first-child):not(:last-child) {
        // border-right: none !important;
// border-left: none !important;
    }

    .border-right-left-none {
        border-right: none !important;
        border-left: none !important;
    }

    tr td:first-child {
        border-right: none !important;
    }

    tr td:last-child {
        border-left: none !important;
    }

    .lab-test-input-container {
        box-shadow: 0px 3px 6px #00000029;
    }

    .border-none-lab-test {
        border: none !important;
    }

    .border-collapse-lab-test {
        border-collapse: collapse !important;
    }

    .submit-button-bottom {
        display: flex;
        justify-content: flex-end;
    }

    .hollow-button-new-diagnosis {
        border: 1px solid #191BBB;
        color: #191BC3;
        width: 95px;
        padding: 6px 0px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        background-color: #fff;
    }

    .fill-button-new-diagnosis {
        border: 1px solid #191BBB;
        color: #fff;
        background-color: #191BC3;
        width: 95px;
        padding: 6px 0px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .button-pair-container {
        gap: 10px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        margin-right: 25px;
    }

    .bottom-line {
        border: 2px solid #F2F1FE;
        width: calc(100% - 50px);
        margin-bottom: 10px;
        margin-top: -25px;
        margin-left: 25px;
        margin-right: 25px;
        justify-content: center;
        align-items: center;
    }

    .border-none {
        // border: none !important;
        border: 0.5px solid #707070 !important;
        border-right: 0.5px solid #707070 !important;
        border-left: 0.5px solid #707070 !important;
    }

    .tr-class {
        td {
            border-right: 0.5px solid #707070 !important;
        }

        // .border-color {
        //   border: 1px solid black;
        // }
    }
}