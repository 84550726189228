 
 


 
 .grid-container{
   display: grid;
  grid-template-columns: 0.3fr 2fr;
  grid-template-rows: auto;
  grid-gap: 10px;  
 column-gap: 30px;

//   grid-column-start: 3;
  align-items: center; 
  text-align: left;
}   
.hospital-details{
    font-size: 40px;
    text-align: center;
}
.medical-prescription{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,

}
.medical-prescription > .text-center > p{
  font-size: 30px;
  font-weight: bold !important;
}
.medical-prescription > .text-center > p:nth-child(2){
 font-size: 20px;
 font-weight: normal;
}
.medical-prescription .grid-container-two{
     display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-gap: 10px;  
 column-gap: 30px;

//   grid-column-start: 3;
  align-items: center; 
  text-align: left;
}
.medical-prescription .grid-container-two p{
    font-weight: bold;
}

.medical-prescription .grid-container-three{
 display: grid;
  grid-template-columns: 0.2fr 0.3fr;
  grid-template-rows: auto;
  grid-gap: 10px;  
 column-gap: 30px;
place-content: center;
//   grid-column-start: 3;
  align-items: center; 
  text-align: left;
}
 