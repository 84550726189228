#create-health-camp {
  margin-left: 20%;
  font-family: "Roboto";
  overflow: auto;
  max-height: 87vh;

  p {
    margin: 0;
    padding: 0;
  }

  .back-btn-row {
    display: inline-flex;
    align-items: center;
    gap: 5%;
    // padding: 1%;
  }

  .chc-container {
    padding: 1% 2%;
    margin: 1.5%;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
  }

  .title-text {
    font-size: 20px;
    font-weight: 700;
    color: #191bc3;
  }

  .select-input {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #70707080;
    border-radius: 5px;
    width: 80%;
    padding: 1% 2%;
    min-height: 44px;
  }

  .datepicker {
    width: 100%;
  }

  /**/
  .MuiInputBase-formControl {
    height: 44px;
  }

  .MuiFormControl-root {
    width: 80%;
  }
  /**/

  .chips-card-doctor {
    border: 0.5px solid #707070;
    border-radius: 5px;
    height: 8rem;
    width: 30rem;
  }
  .chips-card-nurse {
    border: 0.5px solid #707070;
    border-radius: 5px;
    height: 8rem;
    width: 30rem;
  }

  .selected-dct-text {
    color: #76a5de;
    font-size: 14px;
    font-weight: 500;
    padding: 0.5rem 1rem !important;
    margin: 0 !important;
  }

  .assign-nurse{
    margin-left: 122px;
    margin-top: 20px;
  }
  .assign-doctor{
    margin-top: 20px;
  }

  .chips {
    background: #8484f6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    /* opacity: 0.36; */
    font-size: 14px;
    padding: 1%;
    margin: 1%;
    opacity: 0.8;
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
  }

  .close-img {
    margin-left: 2%;
    padding-bottom: 0.25%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .text-assign {
    font-weight: 700;
    color: #191bc3;
    cursor: pointer;
  }

  .btn-reset {
    border: 2px solid #191bc3;
    border-radius: 8px;
    color: #32496b;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 2rem;
  }

  .btn-create {
    background: transparent
      linear-gradient(180deg, #8a68e5 0%, #7997f7 55%, #9b79ef 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border: none;
    padding: 0.5rem 2rem;
  }
}

.file-upload-healtcamp {
  width: 100%;
  height: 230px;
  background-color: #efeefe;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid black;
}
.file-upload-main {
  margin: 6%;
  border: 1px solid black;
  border-radius: 10px;
  width: 70%;
}
