  #addpatients{
.healthcamp-table{
        margin: 3%;
        // box-shadow: 0px 3px 6px #00000029;
        // border: 0.5px solid #707070;
        width: 95%;
        border-collapse: separate;
    }
     thead {
        height: 60px;
        color: #191BC3;
        font-size: 16px;
        font-weight: 500;

        th:first-child, th:last-child{
            text-align: center;
        }
    }
     tbody{
        tr{
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            height: 60px;
        }

        td:first-child,
        td:last-child{
            // border-top: 0.5px solid #707070;
            // border-left: 0.5px solid #707070;
            // border-bottom: 0.5px solid #707070;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
        }
        // td{
        //     border-top: 0.5px solid #707070;
        //     border-bottom: 0.5px solid #707070;
        // }
        img{
            cursor: pointer;
        }
    }
      .healthCamp-table-container{
                 box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }

    .register-patient{
        display: flex;
        background-color: #EBE9FD;
   padding: 93px 170px ;
   align-items: center;
   text-align: center;
   
    }
    .cursor-pointer, .pre-back-btn-row

    {
        cursor: pointer;
    }
   
    .register-outer-container{
       box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding: 100px;
    }
    .btn-reset{
        border:1px solid #191BC3;
        color:#191BC3;
        font-weight: 500 !important;
        
    }
    .btn-submit{
        background-color: #191BC3;
        color:white;
    }
      @media only screen and (max-width: 992px) {
// .healthCamp-table-container{

//   width:fit-content !important;
// }
//  .doct-comp .mobile-class{
//   width: unset !important;
// }
 .healthcamp-table tbody tr td{
  padding: 20px;
}
// .doct-comp .table-style{
//   overflow: scroll !important;
// }
  #patient_container{
  overflow: hidden !important;
}
.healthCamp-table-container{
    overflow-x: scroll !important;
}
}
  }
  