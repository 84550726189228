.authLayoutContainer {}

.authLayout {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background-image: url("./../../../public/assets/images/auth/istockphoto-1386705942-170667a@2x.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.container {
    height: 100%;
}

.containerInnerShell {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.boxShell {
    box-shadow: 15px 15px 50px #0000000D;
    border: 0.5px solid #29309A;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    background: #f5f5f5b8;
    padding: 34px 0px;
}

.form-label {
    margin-bottom: 12px;
    margin-left: 8px;
}

.formShell,
.healthImageShell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.healthImageShell {
    background-image: url('./../../../public/assets/images/auth/PinClipart.com_employment-clip-art_5455146.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
}