.nurse-comp .healthCamp_container {
  margin-left: 20%;
  font-family: 'Roboto';
}

.date-input-add-nurse>div>div>div {
  height: 40px;
}

.nurse-comp .newclass {
  width: calc(100%- 50%) !important;
  background-color: aquad !important;
  // border-radius: 30px;
  // width:200px ;
}

.nurse-comp .cust-input-search-outer {
  background-color: green;
  height: 30px;
  width: 250px;
  border-radius: 300px;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  margin-left: 40px;
  outline: none;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.approve-btn {
  width: 91px;
  height: 35px;
  border: none;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nurse-comp .table-page .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0 !important;
}

.nurse-comp .table-page .MuiTablePagination-root .MuiToolbar-root {
  padding-right: 12px !important;
}

.nurse-comp .cust-input-search-outer .input-group {
  width: 250px;
}

.nurse-comp .addIcon {
  font-size: 18px;
  margin-right: 5px;
}

.nurse-comp .add-doctors {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-right: 10px;
}

.nurse-comp .reset-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  width: 130px;
}

.nurse-comp .input-group .cust-input-search {
  border-bottom-left-radius: 300px;
  border-top-left-radius: 300px;
  width: 20px;
}

// .nurse-comp input[type="text"] {
// height: 30px;
// width: 30px !important;
// }
// .doct-comp input:focus {
// outline: none;
// }
// .doct-comp input[type="text"],
// textarea {
// outline: none;
// box-shadow: none !important;
// border: 1px solid #ccc !important;
// }

.nurse-comp .search-btn-doctor {
  height: 30px;
  position: absolute;
  transform: translateX("50%");
  top: 0;
  border-radius: 20px;
  padding: 1px 15px;
  z-index: 999;
  right: -90px;
  background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
  color: white;
  border: none;
}

// .table-style {
//   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
//     rgb(209, 213, 219) 0px 0px 0px 1px inset;
// }
.nurse-comp .form-style {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding: 15px;
  margin-top: 10px;
  border-radius: 6px;

}

.nurse-comp .add-doctor-form {
  padding: 10px;
  margin-left: 10px;
}

.nurse-comp .table-row-style {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

}

.nurse-comp .doctor-table {
  margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
  // border: 0.5px solid #707070;
  width: 95%;
  border-collapse: separate;
}

.nurse-comp .doctor-table-container {
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  //   rgb(209, 213, 219) 0px 0px 0px 1px inset;
  box-sizing: unset !important;
}


.nurse-comp .back-btn {
  cursor: pointer;
}

.nurse-comp thead {
  height: 60px;
  color: #191BC3;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.nurse-comp tbody {
  tr {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    height: 60px;
    text-align: center;
  }

  .doctor-table thead tr th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }

  .doctor-table thead {
    background-color: #fff;
    z-index: 1;
  }

  .doctor-table-container {
    overflow-y: scroll;
    /* Enable vertical scrolling */
    height: 100vh;
  }



  .nurse-comp td:first-child {
    // border-top: 0.5px solid #707070;
    // border-left: 0.5px solid #707070;
    // border-bottom: 0.5px solid #707070;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
  }

  // td{
  //     border-top: 0.5px solid #707070;
  //     border-bottom: 0.5px solid #707070;
  // }
}


.nurse-comp .label-input {
  position: relative;
  width: 100%;
  margin-left: 0px;
}

.nurse-comp .search-input {
  border-radius: 26px;
  padding-left: 22px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.nurse-comp .search-btn-close {
  position: absolute;
  border: none;
  width: 25px;
  height: 25px;
  line-height: 16px;
  font-size: 12px;
  background: white;
  top: 0;
  bottom: 0px;
  right: 89px;
  margin: auto;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: 0.1s;
  z-index: 10;
  display: block;
}


.nurse-comp .search-input {
  border-radius: 26px;
  padding-left: 22px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 992px) {
  .nurse-comp .doctor-table-container {

    width: fit-content !important;
  }

  .nurse-comp .mobile-class {
    width: unset !important;
  }

  .nurse-table tbody tr td {
    padding: 20px;
  }

  .nurse-comp .table-style {
    overflow: scroll !important;
  }

  .nurse-comp .healthCamp_container {
    overflow-x: hidden;
  }

  .nurse-comp .responsive-search-add {
    display: flex !important;
    flex-direction: column !important;
  }
}

.file-upload {
  width: 230px;
  height: 230px;
  background-color: #EFEEFE;
  cursor: pointer;
  border: 2px solid black;
}

.upload-box {
  height: 40px;
  width: 170px;
  border: 1px solid black;
  background-color: #7070707C;
  opacity: 50%;
  border-radius: 17px;
}

.upload-box p {
  font-size: 12px;
}

.warning-text-card {
  min-height: 8rem;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
}

.doctor-profile-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 2%;
}

.add-btn {
  background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 6px 15px;
  //display: inline-flex;
  align-items: center;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}