

 .border-shadow{
      box-shadow: 0px 3px 6px #00000029;
      background:#EFEEFE 0% 0% no-repeat padding-box ;
      border-radius: 5px;
    }


    .distributedmedicine .dashboard-table-container{
         box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 5px;
  margin-left: 5%;
  margin-right: 5%;
  
  }

 .distributedmedicine .dashboard-table{
      // margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
      // border: 0.5px solid #707070;
      width: 100%;
    //   border-collapse: separate;
  }
  
   .distributedmedicine .dashboard-table thead {
      height: 60px;
      color: #16161c !important;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
  }
  .distributedmedicine thead {
        height: 60px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        background: #8484F6 0% 0% no-repeat padding-box;

        th{
            text-align: center;
            color:white;
        }
    }

   .distributedmedicine tbody{
        tr{
            box-shadow: none !important;

            border: none !important;
            height: 60px;
        }

        td
        {
            // border-top: 0.5px solid #707070;
            // border-left: 0.5px solid #707070;
            // border-bottom: 0.5px solid #707070;  
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
        }
    }

    .view-all{
      background-color: #191BC3;
      color:white;
      padding: 5px 5px;
      border-radius: 4px;
      cursor: pointer;
    }

    .distributedmedicine .MuiTablePagination-root .MuiToolbar-root {
padding-right: 154px !important;
}
    // .MuiToolbar-root 

    @media only screen and (max-width: 992px) {
 .distributedmedicine .dashboard-table-container{

  width:fit-content !important;
}
  .distributedmedicine .mobile-class{
  width: unset !important;
}
 .distributedmedicine .dashboard-table tbody tr td{
  padding: 20px;
}
.distributedmedicine .table-style{
  overflow: scroll !important;
}
 .healthCamp_container{
  overflow-x: hidden;
}
  .responsive-search-add{
  display: flex !important;
  flex-direction: column !important;
}

    } 



    

