#confirm-modal {
  .button-pair {
    gap: 20px
  }

  .hollow-button-modal {
    color: #000;
    background-color: #fff !important;
    background: none;
    border: 1px solid black;
  }

  .button-width {
    width: 100px
  }
}