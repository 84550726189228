.delete-modal {
  .filter-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2rem;
  }

  .select-input {
    background: #efeefe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;
    border: none;
    min-width: 15rem;
    min-height: 44px;
    width: 200px;
  }

  .MuiInputBase-formControl {
    height: 44px;
    background: #efeefe 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;
    border: none;
    width: 15rem;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .delete-modal-header {
    background: #8484f6 0% 0% no-repeat padding-box;
    // border-radius: 8px 8px 0px 0px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    //  border: 1px solid #8484F6;
  }

  .modal-content {
    border: none;
  }

  .btn-card {
    display: flex;
    justify-content: center;
    gap: 1%;
  }

  .btn-cancel {
    border: 2px solid #191bc3;
    border-radius: 8px;
    color: #32496b;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 2rem;
  }

  .main-head {
    display: flex;
    align-items: center;
    
    width: 455px;
    
    // border-radius: 8px;
    // border: none;
    // font-size: 16px;
    // font-weight: 500;
    // width: 455px;
    // padding: 0.5rem 2rem;
  }
  .btn-apply {
    background: transparent
      linear-gradient(180deg, #8a68e5 0%, #7997f7 55%, #9b79ef 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    width: 215px;
    padding: 0.5rem 2rem;
    color: #fff;
  }

  .modal-footer {
    justify-content: center;
    border-top: none;
    margin-bottom: 2rem;
  }

  .btn-card {
    display: flex;
    justify-content: center;
    gap: 15%;
  }
}
