.forum{
  width:100%;
  height: 100vh;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
}
.forum .user-profile-container{
    border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.user-profile-container{
  background-color: #8484F6;
  padding: 15px 20px;
}
.forum-text{
  color: white;
  font-size: 20px;
}
.profile-class  p{
  color:white !important;
  font-size: 12px !important;


}
.dot{
  width: 10px;
  height: 10px;
  background-color: #4FF248;
  border-radius: 100%;
}
.message-container{
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0px;
    background-color: #F2F2FE;

  
}
.message-box{
  height: fit-content;
}
.message-box input{
  border: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  
}
.message-box .input::placeholder {
  color: #75A8FE;
}
.line{
   border-left: 1px solid #75A8FE;
  height: 30px;
  margin: 0 10px;
}
