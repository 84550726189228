#pharmacy{

     .pharmacy .dashboard-table-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    margin-left: 5%;
    outline: none;
    border: none;
    margin-right: 5%;
  }
   .pharmacy .dashboard-table {
    width: 100%;
  }
   .pharmacy .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .pharmacy thead {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: #8484f6 0% 0% no-repeat padding-box;
     th {
      text-align: center;
      color: white;
    }
}
 .pharmacy tbody {
    tr {
      box-shadow: none !important;

      border: none !important;
      height: 60px;
    }

    td {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
    }
  }
  .box{
flex-basis: 0;
flex-grow: 1;
  }
  .desc-box{
    height:80%;
  }
  .save-btn{
   
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 7px !important;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 20px;
        display: inline-flex;
        align-items: center;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    
  }

  .label-input {
    position: relative;
    width: 100%;
}
 .search-input {
    border-radius: 26px;
    padding-left: 22px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
 .search-btn {
    background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px 8px;
    margin-left: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    gap: 4%;
}
}