#patient_container {
    // margin-top: 5%;
    // margin-right: 15% !important;
    padding: 5px;
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 0% !important;

    .label-input {
        position: relative;
        width: 100%;
    }

    .search-btn {
        background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 26px;
        padding: 5px 8px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 4%;
    }

    .search-input {
        border-radius: 26px;
        padding-left: 22px;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .search-btn-close {
        position: absolute;
        border: none;
        width: 25px;
        height: 25px;
        line-height: 16px;
        font-size: 12px;
        background: white;
        top: 0;
        bottom: 0px;
        right: 89px;
        margin: auto;
        padding: 0;
        outline: none;
        cursor: pointer;
        transition: 0.1s;
        z-index: 10;
        display: block;
    }

    .filter-btn {
        background: #5355D8 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
        min-width: 5rem;
    }

    .add-btn {
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
        display: inline-flex;
        align-items: center;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }

    .healthcamp-table {
        margin: 3%;
        // box-shadow: 0px 3px 6px #00000029;
        // border: 0.5px solid #707070;
        width: 95%;
        border-collapse: separate;
    }

    .healthcamp-table {
        margin: auto;
        //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        // rgb(209, 213, 219) 0px 0px 0px 1px inset;
        // padding: 10px;
        // border: 0.5px solid #707070;
        width: 95%;
        border-collapse: separate;
    }


    thead {
        height: 60px;
        color: #191BC3;
        font-size: 16px;
        font-weight: 500;

        th:first-child,
        th:last-child {
            text-align: center;
        }
    }

    tbody {
        tr {
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            height: 60px;
        }

        td:first-child,
        td:last-child {
            // border-top: 0.5px solid #707070;
            // border-left: 0.5px solid #707070;
            // border-bottom: 0.5px solid #707070;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
            border: none;
        }

        // td{
        //     border-top: 0.5px solid #707070;
        //     border-bottom: 0.5px solid #707070;
        // }
        img {
            cursor: pointer;
        }
    }

    .healthCamp-table-container {
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        //     rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }


    .patient-table-page .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        margin: 0 !important;
    }

    .patient-table-page .MuiTablePagination-root .MuiToolbar-root {
        padding-right: 12px !important;
    }

    @media only screen and (max-width: 992px) {
        // .healthCamp-table-container{

        //   width:fit-content !important;
        // }
        //  .doct-comp .mobile-class{
        //   width: unset !important;
        // }
        .healthcamp-table tbody tr td {
            padding: 20px;
        }

        // .doct-comp .table-style{
        //   overflow: scroll !important;
        // }
        #patient_container {
            overflow: hidden !important;
        }

        .healthCamp-table-container {
            overflow-x: scroll !important;
        }
    }

}

#patient_container_healthcamp {
    margin-top: 2% !important;
    // margin-right: 15% !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
    margin-left: 0% !important;

    .label-input {
        position: relative;
        width: 100%;
    }

    .search-btn {
        background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        border-radius: 26px;
        padding: 5px 8px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 4%;
    }

    .search-input {
        border-radius: 26px;
        padding-left: 22px;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .search-btn-close {
        position: absolute;
        border: none;
        width: 25px;
        height: 25px;
        line-height: 16px;
        font-size: 12px;
        background: white;
        top: 0;
        bottom: 0px;
        right: 89px;
        margin: auto;
        padding: 0;
        outline: none;
        cursor: pointer;
        transition: 0.1s;
        z-index: 10;
        display: block;
    }

    .filter-btn {
        background: #5355D8 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
        min-width: 5rem;
    }

    .add-btn {
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 15px;
        display: inline-flex;
        align-items: center;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }

    .healthcamp-table {
        margin: 3%;
        // box-shadow: 0px 3px 6px #00000029;
        // border: 0.5px solid #707070;
        width: 95%;
        border-collapse: separate;
    }

    .healthcamp-table {
        margin: auto;
        //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        // rgb(209, 213, 219) 0px 0px 0px 1px inset;
        // padding: 10px;
        // border: 0.5px solid #707070;
        width: 95%;
        border-collapse: separate;
    }


    thead {
        height: 60px;
        color: #191BC3;
        font-size: 16px;
        font-weight: 500;

        th:first-child,
        th:last-child {
            text-align: center;
        }
    }

    tbody {
        tr {
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            height: 60px;
        }

        td:first-child,
        td:last-child {
            // border-top: 0.5px solid #707070;
            // border-left: 0.5px solid #707070;
            // border-bottom: 0.5px solid #707070;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
            border: none;
        }

        // td{
        //     border-top: 0.5px solid #707070;
        //     border-bottom: 0.5px solid #707070;
        // }
        img {
            cursor: pointer;
        }
    }

    .healthCamp-table-container {
        // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        //     rgb(209, 213, 219) 0px 0px 0px 1px inset;
    }


    .patient-table-page .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        margin: 0 !important;
    }

    .patient-table-page .MuiTablePagination-root .MuiToolbar-root {
        padding-right: 12px !important;
    }

    @media only screen and (max-width: 992px) {
        // .healthCamp-table-container{

        //   width:fit-content !important;
        // }
        //  .doct-comp .mobile-class{
        //   width: unset !important;
        // }
        .healthcamp-table tbody tr td {
            padding: 20px;
        }

        // .doct-comp .table-style{
        //   overflow: scroll !important;
        // }
        #patient_container {
            overflow: hidden !important;
        }

        .healthCamp-table-container {
            overflow-x: scroll !important;
        }
    }

}


.warning-text-card {
    min-height: 8rem;
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
}