#referred-to-mch-tab {
  .tabs-main-container {
    margin-bottom: 20px;
  }

  .refer-to-mch-label {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
  }
}