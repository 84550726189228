#pathologist-view {
  .border-shadow {
    box-shadow: 0px 3px 6px #00000029;
    background: #efeefe 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }

  .pathol-view .dashboard-table-container {
    // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px,
    //   rgb(209, 213, 219) 0px 0px 0px 1px inset;
    width: 40%;
    border-radius: 5px;
    margin-left: 5%;
    outline: none;
    border: none;
    margin-right: 5%;
    padding: 0px !important;
  }

  .edit-button {
    border: none;
    font-size: 15px;
    margin-left: 8px;
    cursor: pointer;
  }

  .pathol-view .dashboard-table {
    width: 100%;
  }

  .pathol-view .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .pathol-view thead {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;

    padding-top: -20px !important;
    border-radius: 10px !important;
    background: #8484f6 0% 0% no-repeat padding-box;

    th {
      text-align: center;
      color: white;
    }
  }

  .pathol-view tbody {
    tr {
      box-shadow: none !important;
      border: none !important;
      height: 10px !important;
    }

    td {
      border: none !important;
      text-align: center;
    }

    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 0px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
    width: 40%;
  }

  .view-all {
    background-color: #191bc3;
    color: white;
    padding: 5px 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  hr {
    border-top: 3px solid #8484ef;
    margin-left: 5%;
    margin-right: 7.5%;
  }

  .desc-box {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #fff;
    outline: none;
    height: 20rem;
    width: 30rem;
  }

  .pathol-view .MuiTablePagination-root .MuiToolbar-root {
    padding-right: 154px !important;
  }

  // .MuiToolbar-root

  @media only screen and (max-width: 992px) {
    .pathol-view .dashboard-table-container {
      width: fit-content !important;
    }

    .pathol-view .mobile-class {
      width: unset !important;
    }

    .pathol-view .dashboard-table tbody tr td {
      padding: 20px;
    }

    .pathol-view .table-style {
      overflow: scroll !important;
    }

    .healthCamp_container {
      overflow-x: hidden;
    }

    .responsive-search-add {
      display: flex !important;
      flex-direction: column !important;
    }
  }
}

#bottom-table-pathol {
  //   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
  padding: 0px 55px 55px 55px;

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  thead {
    height: 0px;
    color: #5355d8;
  }

  thead tr {
    text-align: center;
    height: 20px;
  }

  tbody tr {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
  }

  td {
    text-align: center;
    border: 0.5px solid #707070;
    // padding: 10px;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
  }

  td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  tr td:first-child,
  tr td:last-child {
    border: 1px solid #707070;
  }

  tr td:not(:first-child):not(:last-child) {
    border-right: none;
    border-left: none;
  }

  tr td:first-child {
    border-right: none;
  }

  tr td:last-child {
    border-left: none;
  }

  .viewAll-patho {
    background-color: #1e64d5;
    color: white;
    padding: 5px 5px;
    border-radius: 4px;
    cursor: pointer;
  }
}