.App {
  height: 100%;
}

@import "~bootstrap/scss/bootstrap";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.btn-blue {
  background: #204ef3;
  border: 1px solid #204ef3;
  border-radius: 12px;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}
.required-field {
  color: red;
  margin-left: 5px;
}

.note-text {
  font-size: 14px;
}
.note-text span {
  font-size: 16px;
}
.customDatePicker .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important; /* Set your desired color for the outline */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
