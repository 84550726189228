#tabs-style {
  margin-top: 6px !important;
//   box-shadow: 0px 3px 6px #00000029;

  .paper {
    background-color: "#f8f8f8";
    border-radius: "10px";
    display: "flex";
    justify-content: space-between;
    background-color: red;
    align-items: "flex-start";
  }

  .patient {
    align-self: "flex-end";
  }
}

.table_hedding {
  background-color: red;
}
