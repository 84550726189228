#labtest{

     .lab-test .dashboard-table-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    margin-left: 5%;
    outline: none;
    border: none;
    margin-right: 5%;
  }
   .lab-test .dashboard-table {
    width: 100%;
  }
   .lab-test .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .lab-test thead {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: #8484f6 0% 0% no-repeat padding-box;
     th {
      text-align: center;
      color: white;
    }
}
 .lab-test tbody {
    tr {
      box-shadow: none !important;

      border: none !important;
      height: 60px;
    }

    td {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
    }
  }
  .box{
flex-basis: 0;
flex-grow: 1;
  }
  .desc-box{
    height:80%;
  }
  .save-btn{
   
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 7px !important;
        opacity: 1;
        border: none;
        color: #fff;
        font-size: 16px;
        padding: 6px 20px;
        display: inline-flex;
        align-items: center;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    
  }
}