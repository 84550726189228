#generalInfo{
 border:0.5px solid #70707080 !important;
    padding: 15px;
.grid-container{
   display: grid;
  grid-template-columns: 0fr 2fr;
//   grid-template-rows: auto;
  grid-gap: 10px;
  align-items: start; 
  text-align: left;
}
.right-border{
    border-right: 3px solid #EFEEFD;
    
}

.font-style{
    font-weight: bold !important;
    font-size: 15px;
}

}
