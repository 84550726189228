.single-pair-container {
  display: flex;
  gap: 10px;
}

.single-pair-label {
  color: #1E64D5;
  font-size: 20px;
}

.single-pair-value {
  font-size: 20px;
}

.create-survey-heading {
  color: #191BC3;
  font-size: 32px;
  font-weight: bold;
}

.create-survey-image-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.create-survey-header-container {
  background-color: #8484F666;
  padding-bottom: 20px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}