.test {
    font-size: 30px;
    color: aqua;
}

.search-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9999;
    bottom: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.loginHeading {
    font: normal normal bold 40px/53px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.form {
    max-width: 340px;
    width: 100%;
}

.formContainer {
    padding: 0px 58px !important;
}

.formControl {
    height: 44px !important;
    background: transparent linear-gradient(97deg, #ffffff66 0%, #ffffff24 100%) 0% 0% no-repeat padding-box !important;
    box-shadow: 15px 15px 50px #0000000d;
    border: 1px solid #282f94;
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}

.formLabel {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.forgotPassword {
    font: normal normal medium 14px/19px Roboto;
    letter-spacing: 0px;
    color: #191bc3;
    text-decoration: none;
    cursor: pointer;
}

.loginButton {
    width: 91px;
    height: 35px;
    background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: none;
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    text-align: center;
    font: normal normal medium 14px/19px Roboto;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 991px) {
    .formShell {
        padding-right: 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .formContainer {
        padding: 0px 10px !important;
    }
}