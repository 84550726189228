#copharmacy {

  .copharmacy .dashboard-table-container-pharmacy {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    margin-left: 5%;
    outline: none;
    border: none;
    margin-right: 5%;
  }

  .dashboard-table-container-pharmacy table {
    width: 100% !important;
  }

  .copharmacy .dashboard-table-pharmacy thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .dashboard-table-pharmacy  td{
    // align-items: center;
    text-align: center !important;
  }

  .thead-cls {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: #8484f6 0% 0% no-repeat padding-box;

    th {
      text-align: center;
      color: white;
    }
  }

  .dashboard-table-pharmacy tbody {
    tr {
      box-shadow: none !important;

      // border: none !important;
      height: 60px;
    }
  }
  .box {
    flex-basis: 0;
    flex-grow: 1;
  }

  .desc-box {
    height: 80%;
  }

  .save-btn {

    background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
    border-radius: 7px !important;
    opacity: 1;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 6px 20px;
    display: inline-flex;
    align-items: center;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

  }

  .label-input {
    position: relative;
    width: 100%;
  }

  .search-input {
    border-radius: 26px;
    padding-left: 22px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .search-btn {
    background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px 8px;
    margin-left: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    gap: 4%;
  }

  .dashboard-table-container-pharmacy .dashboard-table-pharmacy-edit {
    width: 100% !important;
  }


}

.container-cls {
  // border:1px solid black;
  margin: 15px;

}

.padding-cls {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
  margin: 15px;
  padding: 15px;

}

.form-cls {
  padding: 15px;
  background-color: #F5F4FE;
}

.medicine-cls {
  font-size: 21px;
}

.inventory {
  background-color: #A8A8F9;
  color: white;
  border-radius: 10px;
  padding: 10px 10px;
  justify-content: center;

}

.date-time {
  font-size: 16px;
  font-weight: bold;
}

.health-camp-name {
  font-size: 20px;
  font-weight: bold;
}

#newclass {

  .dashboard-table-container-pharmacy-edit table {
    width: 100% !important;

  }


  .dashboard-table-pharmacy-edit tbody {
    tr {
      box-shadow: none !important;

      // border: none !important;
      height: 60px;
    }
  }
  .dashboard-table-pharmacy-edit td{
    text-align: center !important;
  }

  .dashboard-table-container-pharmacy-edit table {
    border-collapse: collapse !important;
    //   background-color: #16161c;

    //   border-spacing: 0 10px;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy td {
    //   border-top-left-radius: 5px;
    //   border-bottom-left-radius: 5px;
    border: unset !important;
    text-align: center;
  }

  .thead-cls {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid #8484f6;
    background: #8484f6 0% 0% no-repeat padding-box;

    th {
      text-align: center;
      color: white;
    }
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tbody tr td:first-child {

    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-right: 1px solid black !important;

    text-align: center !important;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit td:last-child {

    //   background-color: #16161c;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:not(:first-child):not(:last-child) {
    border-right: 1px solid black !important;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:first-child {

    border: 1px solid #707070;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:first-child {

    border: 1px solid #707070;
  }

  //   .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:not(:first-child):not(:last-child) {
  //  border-right: none !important;
  //     border-left: none !important;
  //   }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:first-child {
    // border-right: none;
  }

  .dashboard-table-container-pharmacy-edit .dashboard-table-pharmacy-edit tr td:last-child {
    //    border-left: none;
  }

}




//       tr td:first-child,
// +  tr td:last-child {
// +  }