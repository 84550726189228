#health-and-wellness-filter {
  .top-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #C7B9EB;
  }

  .top-title-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .top-name {
    font-size: 20px;
  }

  .cross-icon {
    cursor: pointer;
  }

  .bottom-main-container {
    margin: 20px 20px;
  }

  .select-input {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;
    border: none;
    min-width: 15rem;
    min-height: 44px;
    width: 100%;
    padding: 0px 10px
  }

  .select-pair {
    margin-bottom: 10px;
  }

  .bottom-button-pair {
    display: flex;
    gap: 10px;
    padding: 0px 20px
  }

  .cancel-button {
    background-color: #fff;
    background: none;
    color: #9b79ef;
    border: 1px solid #9b79ef;
  }
}