.btn-reset {
    border: 2px solid #191bc3;
    border-radius: 8px;
    color: #32496b;
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem 2rem;
  }
   .btn-save{
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 2rem;
        color: #fff;  
    }
    .webcam-container{
       display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

    }
    .add-family-member{
      display: inline-flex !important;
      flex-grow: 0  ;
      min-width: 100px;
      margin: auto;
      width: auto !important;
      align-items: flex-end;

    }
    .patient-edit-icon,.patient-delete-icon{
      cursor: pointer;
    }

    .date-pick   .MuiInputBase-root{
      height:40px!important
    }
    .webcam-box{
      position: relative;
      height: 480px;
    }
    .webcam-btn{
      position: absolute;
      bottom: 0;
      color: white;
      
      background-color: #75737475;
      width: 100%;
      display: flex;
      justify-content: center;
      border: 2px solid #161515d9;
     

    }
    .required-field{
        color: red;
  margin-left: 5px;
    }
    .date-input-add-nurse>div>div>div {
      height: 40px;
    }