.hollow-btn {
  border: 1px solid #5355d8;
  padding: 5px 15px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  color: #5355d8;
  background-color: transparent;
}