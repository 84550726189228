#patient_view_container {

    p{
        margin: 0;
        padding: 0;
    }

    font-family: "Roboto";
    overflow: auto;
    max-height: 87vh;

    .back-btn-row {
        display: inline-flex;
        align-items: center;
        gap: 5%;
        cursor: pointer;
    }

    .btn-attach {
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        padding: 0rem 1rem;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 20px;
        max-height: 50px;
        min-height: 50px;
    }

    .add-text{
        font-size: 24px;
        font-weight: 700;
        color: #1E64D5;
        text-align: center;
    }

    .add-card{
        display: flex;
        justify-content: center;
        padding: 0 2rem;
        align-items: center;
    }

    .main-container{
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid #70707080;
        margin: 2% 4%;
        padding: 3%;

        .select-input{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #70707080;
            border-radius: 5px;
            width: 100%;
            padding: 1% 2%;
            min-height: 44px;
        }
    }

    .btn-reset{
        border: 2px solid #191BC3;
        border-radius: 8px;
        color: #32496B;
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 2rem;
    }

    .btn-save{
        background: transparent linear-gradient(180deg, #8A68E5 0%, #7997F7 55%, #9B79EF 100%) 0% 0% no-repeat padding-box;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        padding: 0.5rem 2rem;
        color: #fff;  
    }

    .btn-card{
        display: flex;
        gap: 1rem;
        padding-left: 1%;
    }

    .patient-general-info{
        padding: 10px;
        border: 1px solid #191BC3 !important;
         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .patient-general-info{
        padding: 10px;
        border: 0.5px solid #707070;
        border-radius: 10px;
        // border: 1px solid #191BC3 !important;
        //  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

}


 @media only screen and (max-width: 992px) {

 #patient_view_container .main-container .select-input{
width:100%
 }
 .tablet-size{
    width:80% !important
 }
     }