.separator {
    width: 16px;
}

.verificationCodeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.keyImg {
    width: 64px;
    height: 64px;
    // background-image: url('./../../../public/assets/images/auth/key-img.png');
    background-repeat: no-repeat;
    background-position: center;
}

.verification {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #000352;
}

.code {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #4A7BF1;
    margin-left: 10px;
}

.enterOtp {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.didntReciveCode {
    font: normal normal 300 12px/16px Roboto;
    letter-spacing: 0.78px;
    color: #363636;
}

.resend {
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0.78px;
    color: #034FFD;
}

.verifyButton {
    width: 144px;
    height: 45px;
    background: transparent linear-gradient(180deg, #A080F7 0%, #7997F7 55%, #A381F7 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px !important;
    font: normal normal bold 18px/24px Roboto !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.invalidVerificationCode {
    font: normal normal bold 14px/15px Source Sans Pro;
    letter-spacing: 0px;
    color: #EB4141;
}