.alert-modal-container .modal-content {
    width: 80% !important;
    margin-left: 35px !important;
    /* align-items: center; */
}

.closeIcon{
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    margin-top: 5px;
}
.alert-modal-body {
    text-align: center;
}

.alert-modal-text {
    color: #285892;
    /* font: normal normal bold 20px/30px 'Source Sans Pro'; */
    font-size: 20px;
    font-family: 'montserrat';
    font-weight: bold;
    padding: 0.5rem 0 1rem 0;
}

alert-modal-message {
    color: black;
    font-size: 16px;
    font-family: 'montserrat';
    font-weight: bold;
    padding: 0.5rem 0 1rem 0;
}