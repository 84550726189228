.confirmation-modal{
    .modal-content{
        align-items: center;
    }

    .msg-text{
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
    }
}