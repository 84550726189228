     #npharmacy{
 .pharmacy .dashboard-table-container {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // width: 170%;
    border-radius: 5px;
    margin-left: 5%;
    outline: none;
    border: none;
    margin-right: 5%;
  }
   .pharmacy .dashboard-table {
    width: 100%;
  }
   .pharmacy .dashboard-table thead {
    height: 60px;
    color: #16161c !important;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  .pharmacy thead {
    height: 60px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    background: #8484f6 0% 0% no-repeat padding-box;
     th {
      text-align: center;
      color: white;
    }
}
 .pharmacy tbody {
    tr {
      box-shadow: none !important;

      border: none !important;
      height: 60px;
    }

    td {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
    }
  }
  .box{
flex-basis: 0;
flex-grow: 1;
  }
  .desc-box{
    height:80%;
  }

     }
     
    