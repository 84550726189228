.header-container {
  p {
    margin: 0 !important;
    padding: 0;
    font-family: 'Roboto';
  }

  .admin-menu-hgt {
    height: 8rem !important;
  }

  .dropdown-profile-menu {
    left: -8rem !important;
    // border: none !important;
    // width: 18rem !important;
    justify-content: center !important;
    // padding: 1rem 2rem !important;
    gap: 2rem !important;
    cursor: pointer;
    padding: 0px 0px
  }

  .btn-blue1 {
    // background: #204EF3;
    // border: 1px solid #204EF3;
    border-radius: 12px;
    color: #000;
    padding: 2px;
    text-align: center;
    width: 140px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dropdown-profile {
    background-color: transparent !important;
    border: none !important;
  }

  .dropdown-profile::after {
    content: none !important;
  }

  .dropdown-profile:focus {
    outline: none !important;
    box-shadow: none !important;
  }



  .dp-profile-text {
    font-size: 20px;
    font-family: 'montserrat-regular';
  }

  .dp-profile-img {
    padding-right: 0.75rem;
  }

  .header-row {
    padding: 0.5% 2%;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 72px;
  }

  .userProfileCard {
    display: flex;
    gap: 3%;
    justify-content: end;
    align-items: center;
  }

  .header-coach-card {
    display: flex;
    gap: 4%;
    align-items: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  .profile-name {
    font-size: 20px;
    font-weight: 700;
  }

  .profile-role {
    font-size: 15px;
    font-weight: 500;
  }
}