#healthInfo{
     border:0.5px solid #70707080 !important;
    padding: 15px;

    .right-border{
        border-right: 3px solid #F2F1FD;
    }

  .grid-container{
   display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: auto;
  grid-gap: 10px;  
 column-gap: 30px;

//   grid-column-start: 3;
  align-items: center; 
  text-align: left;
}
.font-style-bold{
    font-weight: bold !important;
}
.font-style{
  font-weight: bold !important;
}
}

