.HexagonWithPercentage span {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  
  .date-picker {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .pie-chart {
    margin-top: 50px;
    text-align: center;
  }