.healthcamp-table {
  margin: 3%;
  /* box-shadow: 0px 3px 6px #00000029;
  border: 0.5px solid #707070; */
  width: 95%;
  border-collapse: separate;
}

.healthCamp-table-container{
  box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
rgb(209, 213, 219) 0px 0px 0px 1px inset;
}