#healthCenterPreferedPatient{
     border:0.5px solid #70707080 !important;
    padding: 15px;

  .grid-container{
   display: grid;
  grid-template-columns: 1fr 2fr;
//   grid-template-rows: auto;
  grid-gap: 10px;
  align-items: start; 
  text-align: left;
}
.font-style-bold{
    font-weight: bold !important;
}

  .dashboard-table-container{
         box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 5px;
  
  }

.view-table{
      margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
      // border: 0.5px solid #707070;
      width: 95%;
      border-collapse: separate;
  }
  
  .graph-border{
    box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  rgb(209, 213, 219) 0px 0px 0px 1px inset;
   
      margin-top: 12px;
      border-radius: 5px;
   
      
      
  }

   .view-table thead {
      height: 60px;
      color: #16161c !important;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
  }
    .view-table thead {
       height: 60px;
      color: #191BC3;
      font-size: 16px;
      font-weight: 500;
      text-align: center;

        th{
            text-align: center;
           color:#191BC3 !important;
        }
    }

     tr {
      
        border: 1px solid black;
       
      }

   .view-table tbody{
        tr{
            box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #707070 !important;
          height: 60px;
          text-align: center;
        }

        td:first-child,
        td:last-child{
            // border-top: 0.5px solid #707070;
            // border-left: 0.5px solid #707070;
            // border-bottom: 0.5px solid #707070;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: center;
        }
    }
   

  .grid-container{
   display: grid;
  grid-template-columns: 1fr 2fr;
//   grid-template-rows: auto;
  grid-gap: 10px;
  align-items: start; 
  text-align: left;

}
.grid-container p{
  font-size: 16px;
}
.doctor-info p:first-child{
font-size: 16px;
font-weight: bold;
}
.doctor-info p:nth-child(2){
font-size: 12px;
// font-weight: bold;
}
.medical-prescription{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,

}
.medical-prescription > .text-center > p{
  font-size: 30px;
  font-weight: bold;
}
.medical-prescription > .text-center > p:nth-child(2){
 font-size: 20px;
 font-weight: normal;
}

.doctor-info-container{

  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
}
.status-recover div{
color: #09A751;
background-color: #BDE8D1;
border-radius: 3px;


} 
.pre-back-btn-row{
  cursor: pointer;
}
#tabelInfo-patient{
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th{
    // border: 1px solid black;
    text-align: left;
    padding: 8px;
  }
}
.grid-container-view-patient{
          display: grid;
 grid-gap:10px;
        grid-template-columns:2fr 1fr;
           align-items: start;
    text-align: left;
    // padding-left: 70px;
}
  

  
  @media only screen and (max-width: 992px) {
 .dashboard-table-container{

  width:fit-content !important;
}
  .mobile-class{
  width: unset !important;
}
 .dashboard-table tbody tr td{
  padding: 20px;
}
.table-style{
  overflow: scroll !important;
}
 .healthCamp_container{
  overflow-x: hidden;
}
  .responsive-search-add{
  display: flex !important;
  flex-direction: column !important;
}

    } 
}

// #Prescription{

   
 
// }


