.doct-comp .healthCamp_container {
  margin-left: 20%;
  font-family: 'Roboto';
}

.reset-btn-mch {
  padding: 5px 40px !important;
  border: 1px solid #7997F7 !important;
  border-radius: 12px !important;
  color: #7997F7 !important;
  margin-right: 10px;



}

.doct-comp .newclass {
  width: calc(100%- 50%) !important;
  background-color: aquad !important;
  // border-radius: 30px;
  // width:200px ;
}

.doct-comp .cust-input-search-outer {
  background-color: green;
  height: 30px;
  width: 250px;
  border-radius: 300px;
  border-top-right-radius: 300px;
  border-bottom-right-radius: 300px;
  border-top-left-radius: 300px;
  border-bottom-left-radius: 300px;
  margin-left: 40px;
  outline: none;
  border: none;
  box-shadow: 0px 3px 6px #00000029;
}

.approve-btn {
  width: 91px;
  height: 35px;
  border: none;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font: normal normal medium 14px/19px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.doct-comp .table-page .MuiTablePagination-root .MuiToolbar-root .MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0 !important;
}

.doct-comp .table-page .MuiTablePagination-root .MuiToolbar-root {
  padding-right: 12px !important;
}

.doct-comp .cust-input-search-outer .input-group {
  width: 250px;
}

.doct-comp .addIcon {
  font-size: 18px;
  margin-right: 5px;
}

.doct-comp .add-doctors {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  outline: none;
  border: none;
  margin-right: 10px;
}

.doct-comp .reset-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  width: 130px;
}

.doct-comp .input-group .cust-input-search {
  border-bottom-left-radius: 300px;
  border-top-left-radius: 300px;
  width: 20px;
}

// .doct-comp input[type="text"] {
// height: 30px;
// width: 30px !important;
// }
// .doct-comp input:focus {
// outline: none;
// }
// .doct-comp input[type="text"],
// textarea {
// outline: none;
// box-shadow: none !important;
// border: 1px solid #ccc !important;
// }

.doct-comp .search-btn-doctor {
  height: 30px;
  position: absolute;
  transform: translateX("50%");
  top: 0;
  border-radius: 20px;
  padding: 1px 15px;
  z-index: 999;
  right: -90px;
  background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
  color: white;
  border: none;
}

// .table-style {
//   box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
//     rgb(209, 213, 219) 0px 0px 0px 1px inset;
// }
.doct-comp .form-style {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  padding: 15px;
  margin-top: 10px;
  border-radius: 6px;

}

.form-style {
  // border: 1px solid black !important;
  border-radius: 20px !important;
  margin-top: 20px !important;
  margin: 20px 20px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset !important;

}

.doct-comp .add-doctor-form {
  padding: 10px;
  // width: 1080px;
  margin-top: -57px;
}

.doct-comp .table-row-style {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

}

.doct-comp .doctor-table {
  margin: auto;
  //     box-shadow:  rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
  // rgb(209, 213, 219) 0px 0px 0px 1px inset;
  // padding: 10px;
  // border: 0.5px solid #707070;
  width: 95%;
  border-collapse: separate;
}

.doct-comp .doctor-table-container {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

}


.doct-comp .back-btn {
  cursor: pointer;
}

.doct-comp thead {
  height: 60px;
  color: #191BC3;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.doct-comp tbody {
  tr {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    height: 60px;
    text-align: center;
  }

  .doct-comp td:first-child {
    // border-top: 0.5px solid #707070;
    // border-left: 0.5px solid #707070;
    // border-bottom: 0.5px solid #707070;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center;
  }

  // td{
  //     border-top: 0.5px solid #707070;
  //     border-bottom: 0.5px solid #707070;
  // }
}


.doctor-comp .label-input {
  position: relative;
  width: 100%;
  margin-left: 0px;
}

.doctor-comp .search-input {
  border-radius: 26px;
  padding-left: 22px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.doctor-comp .search-btn-close {
  position: absolute;
  border: none;
  width: 25px;
  height: 25px;
  line-height: 16px;
  font-size: 12px;
  background: white;
  top: 0;
  bottom: 0px;
  right: 89px;
  margin: auto;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: 0.1s;
  z-index: 10;
  display: block;
}


.doctor-comp .search-input {
  border-radius: 26px;
  padding-left: 22px;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 992px) {
  .doct-comp .doctor-table-container {

    width: fit-content !important;
  }

  .doct-comp .mobile-class {
    width: unset !important;
  }

  .doctor-table tbody tr td {
    padding: 20px;
  }

  .doct-comp .table-style {
    overflow: scroll !important;
  }

  .doct-comp .healthCamp_container {
    overflow-x: hidden;
  }

  .doct-comp .responsive-search-add {
    display: flex !important;
    flex-direction: column !important;
  }
}

.file-upload {
  width: 230px;
  height: 230px;
  background-color: #EFEEFE;
  cursor: pointer;
  border: 2px solid black;
}

.upload-box {
  height: 40px;
  width: 170px;
  border: 1px solid black;
  background-color: #7070707C;
  opacity: 50%;
  border-radius: 17px;
}

.upload-box p {
  font-size: 12px;
}

.warning-text-card {
  min-height: 8rem;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
}

.doctor-profile-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 2%;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}

.customDatePicker .MuiOutlinedInput-notchedOutline {
  border-color: #ced4da !important;
  /* Set your desired color for the outline */
}

.date-input-add-doctor>div>div>div {
  height: 40px;
}