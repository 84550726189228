// profile-Settings-tabs-start

#profile-container {
  .profile-content {
    box-shadow: 10px 10px 10px 10px #00000029;
    margin-top: 2px;
    margin-left: 21%;
    width: 273px;
    height: 525px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
  }

  .img {
    margin-top: 12px;
    margin-left: 17px;
    width: 206px;
    height: 206px;
  }
}

.email-tab {
  display: flex;
  align-items: center;
}

#bar {
  position: absolute;
  height: 219px;
  display: -webkit-inline-box;
}

.edit-icon {
  display: flex;
  position: absolute;
  margin-left: 24px;
  margin-top: 2px;
}

.profile-name {
  margin-left: -130px;
  display: initial;
  margin-top: -52px;
  color: #707070 !important;
}

.edit-prfile-name {
  margin-left: 14px;
  color: #70707073;
  font-size: 16px;
  // margin-top: -2px;
}

.nav-list {
  text-align: initial;
  position: relative;
  margin-top: -15px;
  color: #707070 !important;
  width: 273px;
  margin-left: -29px;
}

.nav-link-name {
  margin-left: 14px !important;
  position: relative;
  color: #707070 !important;
}

.nav-logo-name {
  display: inline-block;
  position: absolute;
  margin-left: 8px;
  margin-top: -24px;
  color: #707070 !important;
}

.nav-link:hover {
  background-color: #efeefe;
  color: black !important;
}


.nav-link-name:hover {
  color: #191bc3 !important;
  font-weight: bold;
}

.nav-link.active {
  color: #191bc3 !important;
  font-weight: bold;
  background-color: #efeefe;
}

.w-auto:hover {
  color: black !important;
}

.bt-names {
  text-decoration: none;
  display: inline-block;
  color: aliceblue;
}

// profile-Settings-tabs-end

// profle-side-contenar-end
.side-content {
  margin-top: -476%;
  width: 732px;
  height: 461px;
  border: 2px solid #7070702b;
  margin-left: 174%;
  position: relative;
}
.side-content-changePass {
  margin-top: -385%;
  width: 732px;
  height: 461px;
  border: 2px solid #7070702b;
  margin-left: 174%;
  position: relative;
}

.profile-side-content {
  margin-top: 0px;
  margin-left: 158px;
  width: 732px;
  height: 461px;
  border: 2px solid #7070702b;
}

.side {
  position: absolute;
  margin-top: -28.9%;
  background-color: #efeefe;
  display: flex;
  width: 732px;
  margin-left: 158px;
  height: 67px;
}

.profile-side {
  margin-top: -420px;
  background-color: #efeefe;
  width: 732px;
  margin-left: 158px;
  height: 67px;
  position: absolute;
  position: inherit;
  display: flex;
}

.profile-tab-titele {
  color: #191bc3;
  font-size: 24px;
}
.profile-tab-title-email {
  color: #191bc3;
  font-size: 24px;
}

.tab-name {
  font-size: 24px;
  display: initial;
  margin-left: 275px;
  margin-top: 72px;
  line-height: 59px;
}

.tab-icons {
  margin-left: 251px;
  position: absolute;
  margin-top: 20px;
  color: #191bc3;
}
.tab-icons-email {
  margin-left: 251px;
  position: absolute;
  margin-top: 24px;
  color: #191bc3;
}

// profle-side-contenar-end

.input-lable {
  display: inline-block;
  margin-top: 25px;
  display: block;
  margin-left: 8px;
  display: block;
  font-size: 16px;
  display: flex;
  font-weight: 500;
}

.from-lable {
  margin-top: 31px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 208px;
  display: table;
}

.input-fild {
  width: 322px;
  border: 1px solid #70707080;
  border-radius: 5px;
}

.eyeOff {
  margin-left: -31px;
  margin-top: 8px;
  position: absolute;
}

.c-pass-titele {
  width: 329px;
  margin-top: 14px;
  font-size: 12px;
  text-align: left;
  color: #000000;
  margin-left: 220px;
}

.button {
  background-color: #8061d5;
  border: none;
  width: 232px;
  height: 40px;
  margin-left: 24px;
  margin-top: 11px;
  border-radius: 8px;
  color: #fff;
}

.button-e {
  margin-top: 34px;
  margin-left: 0px;
  background-color: #8061d5;
  border: none;
  width: 232px;
  border-radius: 8px;
  color: #fff;
  height: 40px;
}

.tab-icons-m {
  margin-top: 23px !important;
  margin-left: 246px !important;
  position: absolute;
}

.edit-btn {
  margin-left: 25%;
  margin-top: 15px;
  display: block;
  background-color: #8061d5;
  border: none;
  width: 85px;
  border-radius: 4px;
  height: 31px;
}

.back-btn-row {
  margin-left: -19% !important;
  display: table-row-group;
}

.update-btn {
  width: 83px;
  background-color: #8061d5;
  border: none;
  color: #fff;
  margin-top: -11px;
  margin-left: -76%;
  border-radius: 4px;
  padding: 4px;
}

.p-edit-icon {
  width: 18px;
}

.form-Otp {
  width: 43px;
  margin-left: 13px;
  height: 43px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #70707080;
}

.otp-section {
  margin-left: -35px;
  margin-top: 105px;
  // display: flow-root;
}

.otp-titel {
  margin-left: -6px;
  margin-top: -75px;
  font-size: 24px;
}

.enetr-otp {
  margin-left: 50px;
  font-size: 16px;
  margin-left: -11px !important;
}

.span-titel {
  position: absolute;
  color: #191bc3;
  margin-left: 154px;
  margin-top: -36px;
  font-weight: bolder;
  font-size: 14px;
}

.contetn-text {
  font-size: 14px;
  color: #000000;
  margin-top: 20px;
  margin-left: -60px;
}

.otp-input {
  margin-left: -29px;
}

.button-otp {
  margin-left: 7px !important;
  margin-top: 15px;
  background-color: #8061d5;
  border: none;
  width: 180px;
  border-radius: 8px;
  color: #fff;
  height: 40px;
}
