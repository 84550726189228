.forgotPasswordContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.forgotText {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #4A7BF1;
}

.passwordText {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #000352;
}

.formLabel {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.requestOTPButton {
    font: normal normal bold 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: transparent linear-gradient(109deg, #623dc6 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: none;
    border-radius: 5px;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    text-align: center;
    width: 170px;
    height: 42px;
}

.formControl {
    height: 45px !important;
    box-shadow: 15px 15px 50px #0000000d;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px) !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #000000 !important;
    border-radius: 12px !important;
}