#sideNavContainer {
  p {
    margin: 0 !important;
    padding: 0 !important;
  }

  position: absolute;
  // top: 5rem;
  height: calc(100% - 5rem);
  width: 20%;
  background: transparent linear-gradient(180deg, #a080f7 0%, #7997f7 55%, #a381f7 100%) 0% 0% no-repeat padding-box;
  transition: 0.5s;

  .sideNavBtnCard {
    text-align: end;
    padding: 1rem;
  }

  @media only screen and (max-width: 992px) {
    .sideNavBtnCard {
      padding: 0 !important;
    }

    ul {
      padding-left: 7% !important;
    }

    a {
      padding: 5px !important;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 15px;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-left: -9px;
  }

  .active {
    background: transparent linear-gradient(98deg, #ffffff65 0%, #ffffff24 100%) 0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #0000000d;
    border: 1px solid #ffffff40;
    border-radius: 30px 0px 0px 30px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  ul {
    list-style-type: none;
    padding-left: 22%;
  }
}

::-webkit-scrollbar {
  width: 10% !important;

}