#top-bar {
  .topbar-main-container {
    background-color: #191BC3;
    color: #fff;
    padding: 12px 30px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
  }
}