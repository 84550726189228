.main-heading-health {
  color: #191BC3;
  font-weight: bold;
}

$block: '.hex-grid';

@mixin grid-item($amount) {
  @for $i from 1 through $amount {
    &:nth-of-type(#{$amount}n + #{$i}) {
      grid-column: #{$i + $i - 1} / span 2;

      @if $i % 2==0 {
        grid-row: calc(var(--counter) + var(--counter) - 1) / span 2;
      }
    }
  }

  @for $i from 1 through 20 {
    &:nth-of-type(n + #{$i * $amount + 1}) {
      --counter: #{$i + 1};
    }
  }
}

#{$block} {
  display: flex;
  justify-content: center;

  &__list {
    --amount: 5;
    position: relative;
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(var(--amount), 1fr 1fr) 1fr;
    grid-gap: 0.0rem 1rem;
  }

  &__item {
    position: relative;
    grid-column: 1 / span 3;
    grid-row: calc(var(--counter) + var(--counter)) / span 2;
    filter: drop-shadow(0 0 10px rgba(#444, .08));
    height: 0;
    padding-bottom: 80%;
  }

  &__content {
    position: absolute;
    height: 50%;
    width: 50%;
    font-size: 1.125rem;
    color: #111111;
    background-color: rgb(118, 5, 5);
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 25%;
    text-decoration: none;
    text-align: center;
    transition: transform .24s ease-out;
  }
}

@media screen and (min-width: 1440px) {
  #{$block} {
    &__list {
      --amount: 3;
      --counter: 1;

      padding: 0;
      margin: 0;
    }

    &__item {
      @include grid-item(3);
    }
  }
}

@media screen and (min-width: 1120px) and (max-width: 1439px) {
  #{$block} {
    &__list {
      --amount: 4;
      --counter: 1;
    }

    &__item {
      @include grid-item(4);
    }
  }
}

@media screen and (min-width: 840px) and (max-width: 1119px) {
  #{$block} {
    &__list {
      --amount: 3;
      --counter: 1;
      grid-gap: 1.5rem 3rem;
    }

    &__item {
      @include grid-item(3);
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 839px) {
  #{$block} {
    &__list {
      --amount: 2;
      --counter: 1;
      grid-gap: 1.5rem 3rem;
    }

    &__item {
      @include grid-item(2);
    }
  }
}

@media screen and (max-width: 479px) {
  #{$block} {
    &__list {
      --amount: 1;
      grid-gap: 1.5rem 3rem;
    }
  }
}

.venue-text {
  font-size: 20px;
  font-weight: bold;
  color: #1E64D5;
}

// #health-and-wellness{
#health-and-wellness {
  .table-border tbody tr td {
    border: 0.5px solid #70707080 !important;
  }

  .health-wellness-table-title {
    font-weight: 600;
    font-size: 24px;
    white-space: nowrap;
  }

  .health-wellness-table-top-container {
    // style={{
    // 	display: "flex",
    // 	flexDirection: "row",
    // 	alignItems: "center",
    // 	gap: 20,
    // }}
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    // margin: 30px 0px
  }

  table thead {
    height: 60px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    // background: #8484f6 0% 0% no-repeat padding-box;

    th {
      text-align: center;
      color: #191BC3;
    }
  }

  .medical-diagnosis-table {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070
  }

  .top-spacing {
    margin-top: 20px;
  }

  // .tabs-main-container {
  //   margin-bottom: 20px;
  // }

  .pharmacy-table-overwrite {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .pdf-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px
  }

  .add-button-pharmacy {
    color: #191BC3;
    font-weight: 500;
  }

  .horizontal-gap {
    margin: 0px 5%;
  }

  // .refer-to-mch-label {
  //   display: flex;
  //   justify-content: flex-start;
  //   font-weight: 500;
  // }

  .new-medicaldiagnosis-title-container {
    padding: 10px 1.7rem !important;
    background-color: #EFEEFE;
    font-weight: 600;
  }

  table {
    border-collapse: separate !important;
    border-spacing: 0 10px;
  }

  .border-collapse {
    border-collapse: collapse !important
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  thead {
    height: 60px;
    color: #5355D8
  }

  thead tr {
    text-align: center;
    height: 20px;

    // background-color: aqua;
  }

  tbody tr {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #707070;
    // background-color: aqua;
  }

  td {
    text-align: center;
    border: 0.5px solid #707070 !important;
    // padding: 10px;
  }

  td:first-child {
    // border-top: 0.5px solid #707070;
    // border-left: 0.5px solid #707070;
    // border-bottom: 0.5px solid #707070;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-align: center;
  }

  td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  tr td:first-child,
  tr td:last-child {
    border: 1px solid #707070;
  }

  tr td:not(:first-child):not(:last-child) {
    border-right: none !important;
    border-left: none !important;
  }

  .border-right-left-none {
    border-right: none !important;
    border-left: none !important;
  }

  tr td:first-child {
    border-right: none !important;
  }

  tr td:last-child {
    border-left: none !important;
  }

  .lab-test-input-container {
    box-shadow: 0px 3px 6px #00000029;
  }

  .border-none-lab-test {
    border: none !important;
  }

  .border-collapse-lab-test {
    border-collapse: collapse !important;
  }

  .submit-button-bottom {
    display: flex;
    justify-content: flex-end;
  }

  .hollow-button-new-diagnosis {
    border: 1px solid #191BBB;
    color: #191BC3;
    width: 95px;
    padding: 6px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #fff;
  }

  .fill-button-new-diagnosis {
    border: 1px solid #191BBB;
    color: #fff;
    background-color: #191BC3;
    width: 95px;
    padding: 6px 0px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .button-pair-container {
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 25px;
  }

  .bottom-line {
    border: 2px solid #F2F1FE;
    width: calc(100% - 50px);
    margin-bottom: 10px;
    margin-top: -25px;
    margin-left: 25px;
    margin-right: 25px;
    justify-content: center;
    align-items: center;
  }

  .border-none {
    // border: none !important;
    border: 0.5px solid #707070 !important;
    border-right: 0.5px solid #707070 !important;
    border-left: 0.5px solid #707070 !important;
  }

  .tr-class {
    td {
      border-right: 0.5px solid #707070 !important;
    }

    // .border-color {
    //   border: 1px solid black;
    // }
  }

}

// }